import { GetJiLuResDetail, GetJiLuRes, GetExamPushRes, SendGEXingExam, AnStuExamDetail, AnKonwExam, AnTestExam, AnStuExam, EditExam, AddExam, DelExam, MoveExam, SendExam, GetExam, TeacherClooectExam, CancelTeacherClooectExam, GetExamDetail } from '@/api/exam'

export default {
  namespaced: true,
  state: {

  },
  mutations: {

  },
  actions: {
    // 老师收藏考试
    async teacollectexam ({ commit }, id) {
      await TeacherClooectExam(id)
    },
    // 老师取消收藏考试
    async cancelteacollectexam ({ commit }, id) {
      await CancelTeacherClooectExam(id)
    },
    // 获取考试详情
    async getexamdetail ({ commit }, id) {
      const res = await GetExamDetail(id)
      return res.data
    },
    // 获取考试
    async getexam ({ commit }, item) {
      const res = await GetExam(item)
      return res.data
    },
    // 点击发布考试
    async sendexam ({ commit }, id) {
      await SendExam(id)
    },
    // 点击移动考试
    async moveexam ({ commit }, item) {
      await MoveExam(item)
    },
    // 点击删除考试
    async delexam ({ commit }, id) {
      await DelExam(id)
    },
    // 点击添加考试
    async addexam ({ commit }, item) {
      await AddExam(item)
      // console.log(res)
    },
    // 点击修改考试
    async editexam ({ commit }, item) {
      await EditExam(item)
      // console.log(res)
    },
    // 按学生查看考试结果
    async anstuexam ({ commit }, item) {
      const res = await AnStuExam(item)
      return res.data
    },
    // 查看学生考试结果详情
    async lookstudetailexam ({ commit }, id) {
      const res = await AnStuExamDetail(id)
      return res.data
    },
    // 按试题正确率查看
    async antestexam ({ commit }, item) {
      const res = await AnTestExam(item)
      return res.data
    },
    // 按知识点掌握度查看考试结果
    async anknowexam ({ commit }, item) {
      const res = await AnKonwExam(item)
      return res.data
    },
    // 点击发送个性化习题
    async sendgexingexam ({ commit }, item) {
      await SendGEXingExam(item)
    },
    // 获取考试推送记录
    async getexampushre ({ commit }, item) {
      const res = await GetExamPushRes(item)
      return res.data
    },
    // 获取考试推送记录结果
    async getjilures ({ commit }, id) {
      const res = await GetJiLuRes(id)
      return res.data
    },
    // 获取考试推送结果详情
    async getjiluresdetail ({ commit }, id) {
      const res = await GetJiLuResDetail(id)
      return res.data
    },
    // 清除考试时 本地存储的 作业内容 和数据
    clearnExamItem () {
      localStorage.removeItem('alltest') // 清除考试所有大题内容
      localStorage.removeItem('classTest') // 清除填写的考试信息
      // localStorage.removeItem('editsectionjob')
      localStorage.removeItem('examId')
      localStorage.removeItem('sectionexam')
    }
  }
}
