<template>
        <div class="title">
          <h4>{{title}}</h4>
          <img src="../../assets/images/chart_tit.png" alt="">
        </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      require: true
    }
  }
}
</script>

<style lang="less" scoped>
    .title{
      position: relative;
      h4{
        width: 150px;
        text-align: center;
        font-size: 18px;
        color: #666666;
        z-index: 999;
      }
      img{
        position: absolute;
        top: 23px;
        left: 0;
        width: 150px;
        height: 5px;
      }
    }
</style>
