import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import constant from './modules/constant'
import homepage from './modules/homepage'
import source from './modules/source'
import testquestion from './modules/testquestion'
import classadmin from './modules/classadmin'
import resour from './modules/resour'
import exam from './modules/exam'
import job from './modules/job'
import getters from './getters'
Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    user,
    constant,
    homepage,
    source,
    testquestion,
    classadmin,
    resour,
    exam,
    job
  },
  getters
})

export default store
