// 登录拦截
import router from '@/router'
import store from '@/store'
import { Message } from 'element-ui'
// 白名单页面
const whiteList = ['/login', '/back', '/qtjump']

// 前置导航守卫
router.beforeEach(async (to, from, next) => {
  const token = store.getters.token // 一定放在导航守卫内获取
  // 1. 先判断是否有token, 如果有token,还要去登录页面, 强制去首页
  if (token) {
    if (to.path === '/login') {
      next('/') // 被next强制拦截的页面是不会进入到后置导航守卫中的,需要手动关闭进度条
    } else {
      // 有token 想去哪就去哪
      // 刷新一下 vuex的数据就没了 所以每次都要 获取
      if (!store.getters.name) {
        await store.dispatch('user/getUserInfo')
      }
      next()
    }
  } else {
    // 3. 没有登录的情况,且去的是白名单页面, 直接放行
    if (whiteList.includes(to.path)) {
      next()
    } else {
      // 没有登录访问有权限的页面 强制去登录
      Message.error('您还没有登录，请重新登录')
      next('/login')
    }
  }
})
