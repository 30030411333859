export default {
  // 头部导航切换
  // navList: ['课堂讲义', '作业巩固', '考试测评', '智能推送', '学情分析', '资源广场', '班级管理'],
  navList: ['课堂讲义', '作业巩固', '考试测评', '资源广场', '班级管理'],
  // 个人中心
  title: ['个人信息', '修改密码', '更换账号'],
  // 科目
  subject: ['全部', '语文', '数学', '英语', '道德与法治', '历史', '地理', '物理', '化学', '生物', '科学', '音乐', '体育', '美术', '校本课程'],
  // 年级
  classes: [
    {
      name: '一年级',
      id: 1
    },
    {
      name: '二年级',
      id: 2
    },
    {
      name: '三年级',
      id: 3
    },
    {
      name: '四年级',
      id: 4
    },
    {
      name: '五年级',
      id: 5
    },
    {
      name: '六年级',
      id: 6
    },
    {
      name: '七年级',
      id: 7
    },
    {
      name: '八年级',
      id: 8
    },
    {
      name: '九年级',
      id: 9
    },
    {
      name: '高一',
      id: 10
    },
    {
      name: '高二',
      id: 11
    },
    {
      name: '高三',
      id: 12
    }
  ],
  // 版本
  version: ['人教版', '江苏教育出版社', '北京师范大学出版社'],
  // 册数
  book: ['上册', '下册'],
  // 发送状态
  sendStatus: [{ name: '全部', id: '' }, { name: '已发送', id: 1 }, { name: '未发送', id: 0 }],
  // 上传文件类型
  fileType: ['微课', '影音', '课件', '教学案', '其它'],
  // 上传试题 难易度
  degree: ['一颗星', '二颗星', '三颗星', '四颗星', '五颗星'],
  // 教材版本
  textver: ['人教版', '人教PEP版三起', '香港朗文', '大象出版社', '江苏教育出版社', '北京师范大学出版社', '部编版', '仁爱科普版', '粤人版', '沪粤版', '外研社版三起', '人教PEP版一起', '译林出版社', '人教A版', '统编版', '先锋外国语', '科学普及出版社', '湘教版'],
  // 展示形式
  showType: ['单选题', '多选题', '判断题', '填空题'],
  // 添加干扰选项
  ganlao: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'S', 'Y', 'Z'],
  // 资源
  database: ['本校资源', '平台资源', '我的资源'],
  // 资源广场
  // databaseres: ['本校资源', '平台资源', '我的资源', '报错资源'],
  databaseres: ['本校资源', '我的资源'],
  // 资源库
  resources: ['讲义库', '作业库', '考试库', '习题库', '素材库'],
  // 我的资源
  my_resources: ['全部', '我上传的', '我收藏的', '发给我的', '我使用过的', '我分享的'],
  // 考试测评
  // testevalu: ['我的班级', '我的试卷', '我的任务'],
  testevalu: ['我的班级', '我的试卷'],
  // 答案开放时间
  answer_time: ['考试完成时显示', '考试截止时间显示', '自定义时间'],
  // 推送记录类型
  pushType: ['全部', '讲义', '作业', '考试', '智能'],
  // 学情分析的左侧导航
  learnvalue: ['班级学情', '学生学情', '小组学情', '错题分析', '学情分析'],
  // 班级学情搜索时间
  classesSeaTime: ['最近7天', '最近14天', '最近30天', '自选时间'],
  // 试题正确率
  correct_rate: ['课堂练习正确率', '课后作业正确率', '考试答案正确率', '整体正确率'],
  // 学情分析 tab 标题
  studyTitle: ['学科', '成绩表', '任务统计'],
  // 报错类型
  errorType: ['全部', '题目混淆', '选项缺失', '答案错误', '错别字', '知识点错误', '其他'],
  // 点击首页制作讲义查看结果 按什么方式查看
  RedType: ['按学生查看', '试题正确率', '知识点掌握度']
}
