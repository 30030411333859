<template>
  <div>
    <div class="pushcon">
              <div class="time">
                <div class="one">推送时间：</div>
                <div class="two">{{item.create_time}}</div>
                <div class="three">老师推送</div>
              </div>
              <div class="time1">
                <div class="one">推送对象：</div>
                <div class="two">{{item.student.map(item=>item.username).join('、')}}</div>
              </div>
              <div class="time">
                <div class="one">完成情况：</div>
                <div class="two">{{item.submit_progress[0]}}/{{item.submit_progress[1]}}</div>
              </div>
    </div>
    <div class="res" @click="lookres(item.id)">查看结果</div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },
  methods: {
    lookres (id) {
      if (this.name === '作业巩固') {
      // 作业查看结果
        this.$router.push({
          path: '/pushresult', query: { name: this.name, id }
        })
      } else {
        // 考试测评
        this.$router.push({
          path: '/pushresultexam', query: { name: this.name, id }
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
        .pushcon{
          padding: 16px 0 16px 16px;
          .time1{
            margin:10px 0;
            display: flex;
            .one{
              // width: 100px;
              font-size: 14px;
              color: #999999;
            }
            .two{
              flex: 1;
              margin:  0 20px 0 10px;
              font-size: 14px;
              color: #666666;
            }
          }
          .time{
            display: flex;
            align-items: center;
            &:nth-child(2){
              margin: 20px 0 15px 0;
            }
            .one{
              font-size: 14px;
              color: #999999;
            }
            .two{
              margin:  0 20px 0 10px;
              font-size: 14px;
              color: #666666;
            }
            .three{
              width: 72px;
              height: 20px;
              border-radius: 2px 2px 2px 2px;
              border: 1px solid #0CC86F;
              font-size: 12px;
              color: #0CC86F;
              text-align: center;
              line-height: 20px;
            }
          }
        }
        .res{
          cursor: pointer;
          position: absolute;
          right: 0;
          bottom: 0;
          width: 108px;
          height: 40px;
          background: #0CC86F;
          border-radius: 5px 5px 5px 5px;
          font-weight: 400;
          font-size: 14px;
          color: #FFFFFF;
          text-align: center;
          line-height: 40px;
        }
</style>
