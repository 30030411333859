import { GetSubject, GetVersion, GetChapters, GetTwoChapter, GetGroupStu } from '@/api/constant'

export default {
  namespaced: true,
  state: {
    SubjectItem: [], // 科目数据
    VersionItem: {}, // 教材版本
    ChapItem: {}, // 章节单元
    TwoChapItem: []// 二级章节单元

  },
  mutations: {
    setSubjectItem (state, item) {
      state.SubjectItem = item
    },
    setVersionItem (state, item) {
      state.VersionItem = item
    },
    setChapItem (state, item) {
      state.ChapItem = item
    },
    setTwoChapItem (state, item) {
      state.TwoChapItem = item
    },
    // 清空章节单元
    ClearChapItem (state, item) {
      // 清空左侧首页
      state.ChapItem = item
      // 清空弹窗里的 二级章节单元
      state.TwoChapItem = item
    }
  },
  actions: {
    // 获取科目数据
    async  getsubject ({ commit }) {
      const res = await GetSubject()
      commit('setSubjectItem', res.data)
      return res.data
    },
    // 获取教材版本
    async getversion ({ commit }, id) {
      const res = await GetVersion(id)
      commit('setVersionItem', res.data)
      return res.data
    },
    // 获取章节单元
    async getchap ({ commit }, item) {
      const res = await GetChapters(item)
      commit('setChapItem', res.data)
      return res.data
    },
    // 获取二级知识点章节单元
    async gettwochap ({ commit }, item) {
      const res = await GetTwoChapter(item)
      commit('setTwoChapItem', res.data)
      return res.data
    },
    // 获取班级和小组
    async getgroupstu ({ commit }, item) {
      const res = await GetGroupStu(item)
      return res.data
    }
  }
}
