import { TeacherCancelCollectHanout, TeacherCollectHanout, PutHanout, GetHanoutList, GetHanoutTestList, GetHanout, SendHanout, Copyhanout, Movehanout, DelHanout, MadeHanout, GetHanoutDetail } from '@/api/homepage'

export default {
  namespaced: true,
  state: {

  },
  mutations: {

  },
  actions: {
    // 获取讲义资源
    async gethanout ({ commit }, item) {
      const res = await GetHanout(item)
      return res.data
    },
    // 发布讲义
    async SendHanout ({ commit }, item) {
      await SendHanout(item)
    },
    // 复制讲义
    async copyhanout ({ commit }, item) {
      await Copyhanout(item)
    },
    // 移动讲义
    async movehanout ({ commit }, item) {
      await Movehanout(item)
    },
    // 删除讲义
    async delhanout ({ commit }, id) {
      await DelHanout(id)
    },
    // 制作讲义
    async madehanout ({ commit }, item) {
      await MadeHanout(item)
    },
    // 获取讲义详情
    async gethanoutdetail ({ commit }, id) {
      const res = await GetHanoutDetail(id)
      return res.data
    },
    // 获取讲义素材列表
    async gethanoutlist ({ commit }, item) {
      const res = await GetHanoutList(item)
      return res.data
    },
    // 获取讲义试题列表
    async gethanouttest ({ commit }, item) {
      const res = await GetHanoutTestList(item)
      return res.data
    },
    // 修改讲义
    async puthanout ({ commit }, item) {
      await PutHanout(item)
    },
    // 老师收藏讲义
    async teacollhanout ({ commit }, id) {
      await TeacherCollectHanout(id)
    },
    // 老师取消收藏讲义
    async teacancollhanout ({ commit }, id) {
      await TeacherCancelCollectHanout(id)
    },
    // 清除制作讲义时 本地存储的 讲义内容 和数据
    clearnHanoutItem () {
      localStorage.removeItem('ResponFileList')
      localStorage.removeItem('classhanout')
      localStorage.removeItem('editsection')
      localStorage.removeItem('hanoutId')
    }
  }
}
