const getters = {
  token: (state) => state.user.token,
  name: (state) => state.user.userInfo.username,
  user_id: (state) => state.user.userInfo.id,
  role: (state) => state.user.userInfo.role,
  avatar: (state) => state.user.userInfo.image,
  mobile: (state) => state.user.userInfo.mobile,
  classes: (state) => state.user.userInfo.classes
}
export default getters
