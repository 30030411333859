<template>
  <div>
      <!-- 左侧导航 -->
      <div class="LeftNav">
        <div class="content">
          <div class="top">
            <el-form inline>
              <el-form-item>
                <el-select @change="ChangeSubject"  :popper-append-to-body="false"  v-model="form.subject" placeholder="请选择">
                  <el-option v-for="item in subject" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-select  @change="ChangeClasses" :popper-append-to-body="false"  v-model="form.grade" placeholder="请选择">
                  <el-option v-for="item in classes" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-select @change="ChangeVersion"  :popper-append-to-body="false"  v-model="form.book_version" placeholder="请选择">
                  <el-option v-for="item in version" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-select @change="ChangeBook"  :popper-append-to-body="false"  v-model="form.volumes" placeholder="请选择">
                  <el-option v-for="(item,index) in book" :key="index" :label="item" :value="index"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div class="bot">
          <div class="title" @click="getAll" :class="{activeAll: checkedAll ==0 }"><p>全部章节</p></div>
          <div v-if="leftNavItem.length" class="allChapter">
            <dl v-for="item in leftNavItem" :key="item.id">
              <dt @click="ChangeOneStatus(item.showContent,item.id)">
                <div class="chname">{{item.name}}</div>
                <div v-if="item.showContent" class="dn"><img src="../../assets/images/dn.png" alt=""></div>
                <div v-else class="up"><img src="../../assets/images/up.png" alt=""></div>
              </dt>
              <dd v-if="item.showContent">
                <div @click="ShowHanout(item1.id)" :class="{activeDD:item1.id == checked }" class="dd" v-for="item1 in item.sub" :key="item1.id">
                  <div class="yuan"></div>
                  <div class="chname1">{{item1.name}}</div>
                </div>
              </dd>
            </dl>
          </div>
          <el-empty v-else description="暂无章节单元..."></el-empty>
          <!-- <div v-else class="nodata">暂无章节单元...</div> -->
        </div>
      </div>
      <!-- 左侧导航 -->
  </div>
</template>

<script>
import Eum from '@/utils/eum'
export default {
  data () {
    return {
      form: {
        subject: +localStorage.getItem('SubId') || 1, // 默认语文
        grade: +localStorage.getItem('ClassId') || 1, // 默认年级
        book_version: 1,
        volumes: +localStorage.getItem('BookId') || 0,
        page: 1,
        size: 999
      },
      show: false,
      subject: [], // 科目
      classes: Eum.classes,
      version: [], // 版本
      book: Eum.book,
      leftNavItem: [], // 章节单元
      checked: localStorage.getItem('TwoChecked') || -1,
      checkedAll: localStorage.getItem('checkALL') || -1
    }
  },
  async  created () {
    await this.getsubject()
    await this.getversion()
    await this.getchap()
  },
  methods: {
    // 获取科目数据
    async getsubject () {
      if (!this.$store.state.constant.SubjectItem.length) {
        this.subject = await this.$store.dispatch('constant/getsubject')
      } else {
        this.subject = this.$store.state.constant.SubjectItem
      }
    },
    // 切换学科 - 存本地
    ChangeSubject (e) {
      localStorage.setItem('SubId', e)
      this.getchap(1)
      this.$emit('ChangeHanout')
    },
    // 切换年级
    ChangeClasses (e) {
      localStorage.setItem('ClassId', e)
      this.getchap(1)
      this.$emit('ChangeHanout')
    },
    // 切换版本
    ChangeVersion (e) {
      localStorage.setItem('VerId', e)
      this.getchap(1)
      this.$emit('ChangeHanout')
    },
    // 切换上下册
    ChangeBook (e) {
      localStorage.setItem('BookId', e)
      this.getchap(1)
      this.$emit('ChangeHanout')
    },
    // 获取教材版本
    async getversion () {
      if (!this.$store.state.constant.VersionItem.version) {
        const res = await this.$store.dispatch('constant/getversion', this.form.subject)
        this.version = res.version
        // 先看本地有没有
        if (localStorage.getItem('VerId')) {
          this.form.book_version = +localStorage.getItem('VerId')
        } else {
          // 本地没有再看 有咩有默认的
          if (res.default.default) {
            this.form.book_version = res.default.id
            // 默认有 在存本地
            localStorage.setItem('VerId', res.default.id)
          }
        }
      } else {
        const res1 = this.$store.state.constant.VersionItem
        this.version = res1.version
        // 先看本地有没有
        if (localStorage.getItem('VerId')) {
          this.form.book_version = +localStorage.getItem('VerId')
        } else {
          // 本地没有再看 有咩有默认的
          if (res1.default.default) {
            this.form.book_version = res1.default.id
            // 默认有 在存本地
            localStorage.setItem('VerId', res1.default.id)
          }
        }
      }
    },
    // 获取章节单元
    async getchap (v) {
      if (v === 1) {
        // 下拉框切换 每次要刷新
        const res = await this.$store.dispatch('constant/getchap', this.form)
        this.leftNavItem = res.results
      } else {
        // console.log(this.$store.state.constant.ChapItem.count, 999)
        // 组件切换走这
        if (!this.$store.state.constant.ChapItem.count) {
          const res = await this.$store.dispatch('constant/getchap', this.form)
          this.leftNavItem = res.results
        } else {
          this.leftNavItem = this.$store.state.constant.ChapItem.results
        }
      }
      // console.log(this.leftNavItem, 898989)
    },
    // 点击一级菜单
    ChangeOneStatus (v, id) {
      this.leftNavItem.forEach(item => {
        if (item.id === id) {
          item.showContent = !item.showContent // 打开当前
        } else {
          item.showContent = false // 关闭其它
        }
      })
    },
    // 点击二级菜单
    ShowHanout (id) {
      this.checkedAll = -1
      localStorage.removeItem('checkALL')
      this.checked = id
      localStorage.setItem('TwoChecked', id)
      localStorage.setItem('TwoSub', id)
      // 发送给父级 提醒父级重新获取数据
      this.$emit('ChangeHanout', 1)
    },
    // 点击全部章节
    getAll () {
      this.checkedAll = 0
      localStorage.setItem('checkALL', 0)
      this.checked = -1
      localStorage.removeItem('TwoChecked')
      localStorage.removeItem('TwoSub')
      // 把所有的关起来
      this.leftNavItem.forEach(item => {
        item.showContent = false // 关闭其它
      })
      // 发送给父级 提醒父级重新获取数据
      this.$emit('ChangeHanout', 'all')
    }
  }
}
</script>

<style lang="less" scoped>
// 左侧导航
.LeftNav{
  &::-webkit-scrollbar {
    width: 10px;//修改滚动条宽度
  }
  &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background: #ccc;
      height: 20px;
  }
  position: fixed;
  width: 295px;
  height: 100%;
  background-color: #fff;
  overflow: hidden;
  overflow: scroll;
  margin-top:80px;
  .content{
    padding: 30px 10px 0 15px;
    /deep/ .top{
      .el-form{
        .el-input{
          width: 120px;
          height: 40px;
          border-radius: 25px 25px 25px 25px;
        }
        //改变下拉框选中时文字的颜色
        .el-select-dropdown__list{
            .selected{
            color: var(--cl-primary) !important;
          }
        }
        //聚焦时 改变框的颜色
        .el-input__inner {
          border-radius: 25px;
          border-color: #D9D9D9;
          &:focus{
            border-color: var(--cl-primary); /* 将这里的颜色代码换成你想要的颜色 */
          }
        }
      }
    }
  }
  .bot{
    .activeAll{
        background: #e5f5ea;
        color: var(--cl-primary) !important;
    }
    .title{
      cursor: pointer;
      width: 100%;
      height: 50px;
      color: #666666;
      font-weight: bold;
      font-size: 16px;
      line-height: 50px;
      p{
        padding-left: 20px;
      }
      &:hover{
        background: #e5f5ea;
        color: var(--cl-primary);
      }
    }
    .allChapter{
      dl{
        cursor: pointer;
        dt{
          color: #666666;
          width: 100%;
          height: 50px;
          display: flex;
          align-items: center;
          .chname{
            margin-right: 20px;
            width: 210px;
            padding-left: 20px;
            font-weight: bold;
            font-size: 16px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            // color: #666666;
          }
          .up{
            img{
                width: 16px;
                height: 16px;
                margin-bottom: 5px;
              }
          }
          .dn{
            img{
                width: 16px;
                height: 16px;
              }
          }
          &:hover{
            background-color: #e5f5ea;
            color: var(--cl-primary) !important;
          }
        }
        .dd{
          color: #666666;
          display: flex;
          padding-left: 30px;
          align-items: center;
          height: 50px;
          img{
            width: 16px;
            height: 16px;
            margin-bottom: 5px;
          }
          .chname1{
            width: 200px;
            margin-left: 20px;
            font-weight: bold;
            font-size: 16px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            // color: #666666;
          }
          .yuan{
                  width: 10px;
                  height: 10px;
                  background-color: #666666;
                  border-radius: 50%;
          }
          &:hover{
            background-color: #e5f5ea;
            color: var(--cl-primary) !important;
                               .yuan{
                     background-color:  var(--cl-primary);
                   }
          }
        }
        .activeDD{
          background-color: #e5f5ea;
          color: var(--cl-primary) !important;
                             .yuan{
                     background-color:  var(--cl-primary);
                   }
        }
      }
    }
  }

}
</style>
