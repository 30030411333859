import Vue from 'vue'
import showMessage from './message.js'
// 注册全局组件
import HeadNav from '@/components/headnav/head-nav.vue'
import HeadNavOne from '@/components/headnav/head-navone.vue'
import LeftNav from '@/components/leftnav/left-nav.vue'
// import LeftNavOne from '@/components/leftnav/left-navone.vue'
import ChartTitle from '@/components/charttitle/chart-title.vue'
import ChartZheone from '@/components/chartzhe/chart-zheone.vue'
import treeChart from '@/components/treechart/tree-chart.vue'
import PerPush from '@/components/perpush/per-push.vue'
import SelectChap from '@/components/selectchap/select-chap.vue'
import {
  Cascader,
  Rate,
  Tooltip,
  Tag,
  Tabs,
  TabPane,
  Form,
  Badge,
  FormItem,
  Input,
  Button,
  MessageBox,
  Select,
  Option,
  Table,
  TableColumn,
  Pagination,
  Dialog,
  Switch,
  Upload,
  Timeline,
  TimelineItem,
  Collapse,
  CollapseItem,
  Empty,
  DatePicker,
  Radio,
  RadioGroup,
  Checkbox,
  CheckboxButton,
  CheckboxGroup,
  Progress
} from 'element-ui'
Vue.use(Cascader)
Vue.use(Rate)
Vue.use(Progress)
Vue.use(Tooltip)
Vue.use(Checkbox)
Vue.use(CheckboxButton)
Vue.use(CheckboxGroup)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Empty)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Button)
Vue.use(Select)
Vue.use(Option)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Pagination)
Vue.use(Dialog)
Vue.use(Switch)
Vue.use(Upload)
Vue.use(Timeline)
Vue.use(TimelineItem)
Vue.use(Badge)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(DatePicker)
Vue.use(Tag)

Vue.component('SelectChap', SelectChap)
Vue.component('PerPush', PerPush)
Vue.component('HeadNav', HeadNav)
Vue.component('LeftNav', LeftNav)
Vue.component('HeadNavOne', HeadNavOne)
// Vue.component('LeftNavOne', LeftNavOne)
Vue.component('ChartTitle', ChartTitle)
Vue.component('ChartZheone', ChartZheone)
Vue.component('treeChart', treeChart)
Vue.prototype.$message = showMessage

Vue.prototype.$confirm = MessageBox.confirm
