import { GetHanout, GetWork, GetKaoShi, GetXiti, GetSucai, GetAllTeacher } from '@/api/resour'

export default {
  namespaced: true,
  state: {

  },
  mutations: {

  },
  actions: {
    // 获取讲义库资源
    async gethanout ({ commit }, item) {
      const res = await GetHanout(item)
      return res.data
    },
    // 获取作业库的资源
    async getwork ({ commit }, item) {
      const res = await GetWork(item)
      return res.data
    },
    // 获取考试库的资源
    async getkaoshi ({ commit }, item) {
      const res = await GetKaoShi(item)
      return res.data
    },
    // 获取习题库的资源
    async getxiti ({ commit }, item) {
      const res = await GetXiti(item)
      return res.data
    },
    // 获取素材库的资源
    async getsucai ({ commit }, item) {
      const res = await GetSucai(item)
      return res.data
    },
    // 发请求获取所有老师
    async getallteacher ({ commit }, item) {
      const res = await GetAllTeacher(item)
      return res.data.results
    }
  }
}
