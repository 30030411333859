import request from '@/utils/request'

// 老师收藏作业
export const TeacherClooectJob = (id) => request.post(`/resources/collect_homework/${id}/`)

// 取消收藏作业
export const CancelTeacherClooectJob = (id) => request.delete(`/resources/collect_homework/${id}/`)

// 获取作业详情
export const GetJobDetail = (id) => request.get(`/resources/homework/${id}/`)

// 获取作业信息
export const GetJob = (data) => request.get('/resources/homework/', { params: data })

// 移动作业
export const MoveJob = (data) => request.put(`/resources/move_homework/${data.id}/`, data)

// 发布作业
export const SendWork = (id) => request.put(`/resources/publish_homework/${id}/`)

// 删除作业
export const DelWork = (id) => request.delete(`/resources/homework/${id}/`)

// 添加作业
export const AddWork = (data) => request.post('/resources/homework/', data)

// 修改作业
export const PutWork = (data) => request.put(`/resources/homework/${data.id}/`, data)

// 按学生查看作业结果
export const LookStuRes = (data) => request.get('/census/homework_student_result/', { params: data })

// 按学生查看 查看详情
export const LookStuDetail = (id) => request.get(`/census/homework_student_result/${id}/`)

// 按试题正确率查看作业结果
export const LookrateRes = (data) => request.get(`/census/homework_topic_result/${data.homework}/`, { params: data })

// 按知识点掌握度
export const LookKnowRes = (data) => request.get(`/census/homework_know_point_result/${data.homework}/`, { params: data })

// 发送个性化习题
export const PushTest = (data) => request.post('/census/push_exercises/', data)

// 获取作业推送记录
export const GetWorkPush = (id) => request.get(`/census/push_exercises/?homework=${id}`)

// 获取作业推送结果
export const GetWorkPushRes = (id) => request.get(`/census/push_exercises_result/?push_exercises=${id}`)

// 获取作业推送结果详情
export const GetWorkPushDetail = (id) => request.get(`/census/push_exercises_result/${id}/`)
