<template>
  <div id="app">
     <!-- <keep-alive  :include="cachedViews" :exclude="normalViews">
            <router-view />
      </keep-alive> -->
      <router-view />
  </div>

</template>

<script>
export default {
  // data () {
  //   return {
  //     cachedViews: ['assign-work'], // 需要缓存的页面数组
  //     normalViews: [] // 不需要缓存的页面数组
  //   }
  // },
  // watch: {
  //   $route (to, from) {
  //     // 将其他页面添加到缓存
  //     if (
  //       !this.cachedViews.includes(to.name) &&
  //       !this.normalViews.includes(to.name)
  //     ) {
  //       this.cachedViews.push(to.name)
  //     }
  //   }
  // }

  // mounted () {
  //   // 阻止鼠标双击复制文本的事件
  //   document.addEventListener('dblclick', function (event) {
  //     event.preventDefault()
  //   })
  // }
}
</script>

<style lang="less" scoped>

</style>
