import request from '@/utils/request'

// 老师收藏考试
export const TeacherClooectExam = (id) => request.post(`/resources/collect_exam/${id}/`)

// 取消收藏考试
export const CancelTeacherClooectExam = (id) => request.delete(`/resources/collect_exam/${id}/`)

// 获取考试详情
export const GetExamDetail = (id) => request.get(`/resources/exam/${id}/`)

// 获取考试
export const GetExam = (data) => request.get('/resources/exam/', { params: data })

// 点击发布考试
export const SendExam = (id) => request.put(`/resources/publish_exam/${id}/`)

// 点击移动考试
export const MoveExam = (data) => request.put(`/resources/move_exam/${data.id}/`, data)

// 点击删除考试
export const DelExam = (id) => request.delete(`/resources/exam/${id}/`)

// 点击添加考试
export const AddExam = (data) => request.post('/resources/exam/', data)

// 点击修改考试
export const EditExam = (data) => request.put(`/resources/exam/${data.id}/`, data)

// 按学生查看考试结果
export const AnStuExam = (data) => request.get('/census/exam_student_result/', { params: data })

// 按学生查看考试结果详情
export const AnStuExamDetail = (id) => request.get(`/census/exam_student_result/${id}/`)

// 按试题正确率查看
export const AnTestExam = (data) => request.get(`/census/exam_topic_result/${data.exam}/`, { params: data })

// 按知识点掌握度查看考试结果
export const AnKonwExam = (data) => request.get(`/census/exam_know_point_result/${data.exam}/`, { params: data })

// 点击个性化推送
export const SendGEXingExam = (data) => request.post('/census/push_exercises/', data)

// 获取考试推送记录
export const GetExamPushRes = (id) => request.get(`/census/push_exercises/?exam=${id}`)

// 获取考试推送结果
export const GetJiLuRes = (id) => request.get(`/census/push_exercises_result/?push_exercises=${id}`)

// 获取考试推送结果详情
export const GetJiLuResDetail = (id) => request.get(`/census/push_exercises_result/${id}/`)
