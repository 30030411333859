import { UploadTestQUestion, GetTestQuestionDetail, PutTsetQuestion, ContactTest, CancelContactTest } from '@/api/testquestion'

export default {
  namespaced: true,
  state: {

  },
  mutations: {

  },
  actions: {
    // 上传试题
    async  UploadTestQUestion ({ commit }, item) {
      const res = await UploadTestQUestion(item)
      return res.data
    },
    // 获取试题详情
    async gettestdetail ({ commit }, id) {
      const res = await GetTestQuestionDetail(id)
      return res.data
    },
    // 修改试题
    async puttestquestion ({ commit }, id) {
      const res = await PutTsetQuestion(id)
      return res.data
    },
    // 收藏试题
    async contacttest ({ commit }, id) {
      const res = await ContactTest(id)
      return res
    },
    // 取消收藏试题
    async cancelcontacttest ({ commit }, id) {
      const res = await CancelContactTest(id)
      return res
    }
  }
}
