import request from '@/utils/request'

// 添加素材
export const AddSource = (data) => request.post('/resources/vegetable/', data)

// 收藏素材
export const ContactSource = (id) => request.post(`/resources/collect_vegetable/${id}/`)

// 取消收藏
export const CancelContactSource = (id) => request.delete(`/resources/collect_vegetable/${id}/`)

// 获取素材详情
export const GetSource = (id) => request.get(`/resources/vegetable/${id}/`)
