<template>
  <div>
    <el-dialog
    :close-on-click-modal="false"
    @open="open"
    title="关联知识点"
    :visible="dialogVisible"
    :before-close="handleClose">
    <div class="point">
          <el-form inline label-width="100px">
              <el-form-item >
                <el-select v-model="form.subject" placeholder="请选择科目">
                  <el-option v-for="item in subject" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item >
                <el-select v-model="form.book_version" placeholder="请选择教材">
                  <el-option v-for="item in textver" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
               <el-form-item>
                <el-select v-model="form.grade" placeholder="请选择年级">
                  <el-option v-for="item in classes" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
               <el-form-item>
                <el-select v-model="form.volumes" placeholder="请选择上下册">
                  <el-option v-for="(item,index) in book" :key="index" :label="item" :value="index"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item class="sel_point"  style="display:block" label="关键词搜索 :">
                <el-input clearable v-model="form.know_name" placeholder="请输入知识点名称"></el-input>
                <el-button style="margin-left:20px;" @click="search">搜索</el-button>
              </el-form-item>
          </el-form>
          <div class="sel_content">
            <div class="left">
              <h4>知识点库</h4>
              <div v-if="TwoKnowlegItem" class="left_con">
                <dl v-for="item in TwoKnowlegItem" :key="item.id">
                  <dt @click="ChangeOneStatus(item.showContent,item.id)">
                    <div class="chname">{{item.name}}</div>
                    <div v-if="item.showContent" class="dn"><img src="../../assets/images/dn.png" alt=""></div>
                    <div v-else class="up"><img src="../../assets/images/up.png" alt=""></div>
                  </dt>
                  <dd v-if="item.showContent">
                    <div  @click="ClickTwo(item1.id,item1.name)" :class="{activeDD:item1.id === checked }" class="dd" v-for="(item1,index1) in item.children" :key="index1">
                      <div class="yuan"></div>
                      <div class="chname1">{{item1.name}}</div>
                    </div>
                  </dd>
                </dl>
              </div>
               <div v-else class="left_con"><el-empty  description="暂无知识点库"></el-empty></div>
            </div>
            <div class="right">
              <h4>已选择知识点</h4>
              <div class="left_con">
                <div class="content">
                  <ul>
                    <li v-for="item in selData" :key="item.id" >
                      <p>{{item.name}}</p>
                      <span @click="del(item.id)">删除</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button  @click="handleClose(0)">取 消</el-button>
      <el-button type="primary" @click="handleClose(1)">确 定</el-button>
    </span>
  </el-dialog>
  </div>
</template>

<script>
import Eum from '@/utils/eum'
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      activeName: 'first',
      form: {
        subject: +localStorage.getItem('SubId') || 1, // 默认语文
        grade: +localStorage.getItem('ClassId') || 1, // 默认年级
        book_version: +localStorage.getItem('VerId') || 1, // 默认默认版本
        volumes: +localStorage.getItem('BookId') || 0,
        know_name: ''// 关键词搜索
      },
      subject: [],
      textver: [],
      classes: Eum.classes,
      book: Eum.book,
      currentPage4: 1,
      selData: [],
      checked: -1,
      TwoKnowlegItem: []// 二级章节和知识的数据
    }
  },
  async created () {
    await this.getsubject()
    await this.getversion()
  },
  methods: {
    // 打开前 回显最新的数据
    open () {
      this.getTwoKnowleg()
    },
    // 下拉框携带参数
    option () {
      this.form.subject = +localStorage.getItem('SubId') || 1
      this.form.grade = +localStorage.getItem('ClassId') || 1
      this.form.book_version = +localStorage.getItem('VerId') || 1
      this.form.volumes = +localStorage.getItem('BookId') || 0
    },
    // 获取二级章节和知识的数据
    async getTwoKnowleg () {
      this.option()
      this.TwoKnowlegItem = await this.$store.dispatch('constant/gettwochap', this.form)
    },
    handleClose (v) {
      if (v === 1) {
        // 点击确定
        // 发射数据给父组件
        this.$emit('selKnowleg', this.selData)
      } else {
        // 点击取消
        if (this.selData.length) return this.$message('请清空已选择知识点')
        this.$emit('selKnowleg')
      }
      // 关闭所有 展示项
      if (this.TwoKnowlegItem) {
        this.TwoKnowlegItem.forEach(item => {
          item.showContent = false // 关闭其它
        })
      }
      this.option()
      this.selData = []
      // 清空关键词 数据
      this.form.know_name = ''
    },
    // 切换 显示 隐藏 知识点列表
    ChangeOneStatus (v, id) {
      // console.log(v, id, 8888)
      this.TwoKnowlegItem.forEach(item => {
        if (item.id === id) {
          item.showContent = !item.showContent // 打开当前
        } else {
          item.showContent = false // 关闭其它
        }
      })
    },
    // 点击知识点 进行选择
    ClickTwo (id, name) {
      this.checked = id
      // 判断已选择的地方有没有该数据
      const temp = this.selData.filter(item => item.id === id)
      if (temp.length) return this.$message('该知识点已经被选择')
      this.selData.push({ name, id })
    },
    // 点击已经被选择的知识点 点击删除
    del (id) {
      this.selData = this.selData.filter(item => item.id !== id)
    },
    // 获取科目数据
    async getsubject () {
      if (!this.$store.state.constant.SubjectItem.length) {
        this.subject = await this.$store.dispatch('constant/getsubject')
      } else {
        this.subject = this.$store.state.constant.SubjectItem
      }
    },
    // 获取教材版本
    async getversion () {
      if (!this.$store.state.constant.VersionItem.version) {
        const res = await this.$store.dispatch('constant/getversion', this.form.subject)
        this.textver = res.version
      } else {
        const res1 = this.$store.state.constant.VersionItem
        this.textver = res1.version
      }
    },
    // 点击搜索
    async search () {
      if (this.form.subject === '' && this.form.volumes === '' && this.form.grade === '' && this.form.book_version === '' && this.form.know_name === '') return this.$message('请输入搜索内容')
      // 先清空选择的知识点
      // if (!this.selData.length) return this.$message('请清空已选择知识点')
      this.TwoKnowlegItem = await this.$store.dispatch('constant/gettwochap', this.form)
      // console.log(this.TwoKnowlegItem, '9966')
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .el-dialog{
    background: #f5f5f5 ;
    border-radius: 10px 10px 10px 10px;
    .el-dialog__body{
      background: #f5f5f5 ;
      padding: 20px 15px;
    }
    .el-dialog__header{
      border-radius: 10px 10px 0 0;
      background-color: #fff;
    }
    .el-dialog__close{
      font-size: 22px;
      &:hover{
        color: var(--cl-primary);
      }
    }
    .point{
      padding: 0 10px;
      .el-tabs__item{
        font-size: 15px;
        color: #666666;
      }
      .el-input{
         width: 140px;
        input{
          width: 140px;
        }
      }
      .sel_point{
        .el-input{
          width: 200px;
          input{
            width: 200px;
          }
        }
      }
      .sel_content{
        display: flex;
        justify-content: space-between;
        .left{
          h4{
            font-size: 14px;
            color: #333333;
            margin-bottom: 15px;
          }
          .left_con{
            overflow: hidden;
            overflow: auto;
            width: 350px;
            height: 330px;
            background: #fff;
            border-radius: 5px 5px 5px 5px;
            dl{
              cursor: pointer;
              dt{
                color: #666666;
                // padding: 10px 10px;
                width: 100%;
                height:40px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .up{
                  img{
                    margin-bottom: 5px;
                  }
                }
                .chname{
                  width: 250px;
                  padding-left: 20px;
                  font-weight: bold;
                  font-size: 14px;
                   white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  // color: #666666;
                }
                img{
                  width: 14px;
                  height: 14px;
                  margin-right: 20px;
                }
                &:hover{
                  background-color: #e5f5ea;
                  color: var(--cl-primary) !important;
                }
              }
              .dd{
                color: #666666;
                display: flex;
                padding-left: 30px;
                align-items: center;
                height: 35px;
                img{
                  width: 10px;
                  height: 10px;
                }
                .yuan{
                  width: 10px;
                  height: 10px;
                  background-color: #666666;
                  border-radius: 50%;
                }
                .chname1{
                  width: 250px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  margin-left: 20px;
                  font-weight: bold;
                  font-size: 14px;
                  // color: #666666;
                }
                &:hover{
                   .yuan{
                     background-color:  var(--cl-primary);
                   }
                  background-color: #e5f5ea;
                  color: var(--cl-primary) !important;
                }
              }
              .activeDD{
                .yuan{
                  background-color:  var(--cl-primary);
                }
                background-color: #e5f5ea;
                color: var(--cl-primary) !important;
              }
            }
          }
          .page{
              margin-top: 20px;
              width: 350px;
              display: flex;
              justify-content: center;
          }
        }
        .right{
          h4{
            font-size: 14px;
            color: #333333;
            margin-bottom: 15px;
          }
          .left_con{
            overflow: hidden;
            overflow: auto;
            width: 350px;
            height: 330px;
            background: #fff;
            border-radius: 5px 5px 5px 5px;
            .content{
              padding: 0px 10px;
              ul{
                li{
                  height: 40px;
                  display: flex;
                  width: 100%;
                  justify-content: space-between;
                  align-items: center;
                  p{
                    width: 250px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    font-size: 14px;
                    color: #666666;
                  }
                  span{
                    cursor: pointer;
                    font-size: 13px;
                    color: #F14040;
                  }
                }
              }
            }
          }
            .page{
              margin-top: 20px;
              width: 350px;
              display: flex;
              justify-content: center;
            }
        }
      }
    }
    .el-dialog__footer{
      padding: 20px 20px;
      background-color: #fff;
      border-radius: 10px;
      text-align: center;
      .el-button{
        &:first-child{
          margin-right: 30px;
        }
      }
    }
}
</style>
