import { DownClient, reqLogin, GetUserInfo, GetPhoneCode, PutPassword, PutUserInfo, CancelCoun } from '@/api/user'
import { setToken, getToken, removeToken } from '@/utils/cookie.js'
export default {
  namespaced: true,
  state: {
    token: getToken() || '',
    userInfo: {} // 用户信息
  },
  mutations: {
    // 存储token的mutation
    setToken (state, token) {
      // 将token存储到vuex中
      state.token = token
      // 将token存储到cookie中持久化
      setToken(token)
    },
    // 设置个人信息的mutation
    setUserInfo (state, info) {
      state.userInfo = info
    },
    // 移除token
    removeToken (state) {
      state.token = '' // vuex中清除
      removeToken() // 本地清除
    },
    // 移除个人信息
    removeUserInfo (state) {
      state.userInfo = {}
    }
  },
  actions: {
    // 登录
    async  login ({ commit }, loginForm) {
      const res = await reqLogin(loginForm)
      // console.log(res, 888)
      commit('setToken', res.data.token)
      return res
    },
    // 获取用户信息
    async getUserInfo ({ commit }) {
      const res = await GetUserInfo()
      // console.log(res, 898989)
      commit('setUserInfo', res.data)
    },
    // 获取手机验证码
    async getCode ({ commit }, item) {
      const res = await GetPhoneCode(item)
      return res
    },
    // 重置密码
    async putPassword ({ commit }, item) {
      await PutPassword(item)
    },
    // 修改用户信息
    async putuserinfo ({ commit }, item) {
      await PutUserInfo(item)
    },
    // 注销账号
    async cancel ({ commit }, id) {
      await CancelCoun(id)
    },
    // 下载客户端的链接
    async downclient ({ commit }) {
      const res = await DownClient()
      return res.data
    },
    // 退出登录
    logout ({ commit }) {
      // 移除token
      commit('removeToken')
      // 移除用户信息
      commit('removeUserInfo')
      // 清除本地 //获取本地存储的key
      // console.log(Object.keys(localStorage), 99)
      // console.log(Object.keys(sessionStorage), 99)
      Object.keys(localStorage).forEach(function (key) {
        localStorage.removeItem(key)
      })
      Object.keys(sessionStorage).forEach(function (key) {
        sessionStorage.removeItem(key)
      })
    }
  }
}
