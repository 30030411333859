import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  // qt跳转页
  {
    path: '/qtjump',
    component: () => import('@/views/qtjump/qt-jump.vue')
  },
  // 配置一级路由
  {
    path: '/',
    redirect: '/index'
  },
  // 登录
  {
    path: '/login',
    component: () => import('@/views/login/login-page.vue')
  },
  // 忘记密码页
  {
    path: '/back',
    component: () => import('@/views/backpass/back-pass.vue')
  },
  // 首页
  {
    path: '/index',
    component: () => import('@/views/homepage/home-page.vue')
  },
  // 制作讲义页面
  {
    path: '/madehand',
    component: () => import('@/views/madehand/made-hand.vue')
  },
  // 上传素材页面
  {
    path: '/uploadmater',
    component: () => import('@/views/madehand/uploadmater/upload-mater.vue')
  },
  // 上传试题页面
  {
    path: '/uploadtest',
    component: () => import('@/views/madehand/uploadtest/upload-test.vue')
  },
  // 查看结果页面-作业
  {
    path: '/lookres',
    component: () => import('@/views/homepage/lookres/look-result.vue')
  },
  // 查看结果页面-考试
  {
    path: '/lookresexam',
    component: () => import('@/views/testevalu/lookresexam/look-result.vue')
  },
  // 个性化推送 -考试
  {
    path: '/pushstuexam',
    component: () => import('@/views/testevalu/lookresexam/push-student.vue')
  },
  // 个性化推送
  {
    path: '/pushstu',
    component: () => import('@/views/homepage/lookres/push-student.vue')
  },
  // 从资源库里添加页面
  {
    path: '/database',
    component: () => import('@/views/madehand/uploaddatabase/upload-data.vue')
  },
  // 作业巩固页面
  {
    path: '/work-consoli',
    component: () => import('@/views/workconsoli/work-consoli.vue')
  },
  // 查看结果页面
  {
    path: '/lookreswork',
    component: () => import('@/views/workconsoli/lookres/look-result.vue')
  },
  // 个性化推送-作业
  {
    path: '/pushstu1',
    component: () => import('@/views/workconsoli/lookres/push-student.vue')
  },
  // 个性化推送-考试测评
  {
    path: '/pushstuexam',
    component: () => import('@/views/testevalu/lookresexam/push-student.vue')
  },
  // 个性化推送上传试题页面-考试
  {
    path: '/uploadtestpushexam',
    component: () => import('@/views/testevalu/lookresexam/uploadtestpushexam/upload-testpushexam.vue')
  },
  // 个性化推送上传试题页面-作业
  {
    path: '/uploadtestpush',
    component: () => import('@/views/workconsoli/lookres/uploadtestpush/upload-testpush.vue')
  },
  // 个性化推送查看结果-作业
  {
    path: '/pushresult',
    component: () => import('@/views/workconsoli/pushresult/push-result.vue')
  },
  // 个性化推送查看结果-考试
  {
    path: '/pushresultexam',
    component: () => import('@/views/testevalu/pushresultexam/pushexam-result.vue')
  },
  // 个性化推送 考试测评
  {
    path: '/testperpush',
    component: () => import('@/views/testevalu/perpush/per-push.vue')
  },
  // 点击飞机页面
  {
    path: '/perpush',
    component: () => import('@/views/workconsoli/perpush/per-push.vue')
  },
  // 布置作业
  {
    path: '/assignwork',
    component: () => import('@/views/workconsoli/assignwork/assign-work.vue'),
    name: 'assign-work'

  },
  // 布置作业上传试题页面
  {
    path: '/uploadtestjob',
    component: () => import('@/views/workconsoli/uploadtestjob/upload-testjob.vue')
  },
  // 考试测评页面
  {
    path: '/testevalu',
    component: () => import('@/views/testevalu/test-evalu.vue')
  },
  // 考试测评上传试题
  {
    path: '/uploadtesttest',
    component: () => import('@/views/testevalu/uploadtesttest/upload-testtest.vue')
  },
  // 文件详情页面
  {
    path: '/filedetail',
    component: () => import('@/views/resourceplaza/filedetail/file-detail.vue')
  },
  // ppt详情页面
  {
    path: '/pptdetail',
    component: () => import('@/views/resourceplaza/pptdetail/ppt-detail.vue')
  },
  // 题目详情页面
  {
    path: '/topicdetail',
    component: () => import('@/views/resourceplaza/topicdetail/topic-detail.vue')
  },
  // 试卷详情页面
  {
    path: '/testdetail',
    component: () => import('@/views/testevalu/testdetail/test-detail.vue')
  },
  // 发布考试页面
  {
    path: '/sendtest',
    component: () => import('@/views/testevalu/sendtest/send-test.vue')
  },
  // 个人中心页面
  {
    path: '/usercenter',
    component: () => import('@/views/usercenter/user-center.vue')
  },
  // 智能推送页面
  {
    path: '/intelpush',
    component: () => import('@/views/intelpush/intel-push.vue')
  },
  // 推送记录页面
  {
    path: '/pushrecord',
    component: () => import('@/views/intelpush/pushrecord/push-record.vue')
  },
  // 学情分析页面
  {
    path: '/learnanaly',
    component: () => import('@/views/learnanaly/learn-analy.vue')
  },
  // 学情分析 知识点 表格 详情页面
  {
    path: '/knowdetail',
    component: () => import('@/views/learnanaly/components/learndetail/knowtable-detail.vue')
  },
  // 学情分析 成绩表 详情页面
  {
    path: '/scoredetail',
    component: () => import('@/views/learnanaly/components/learndetail/score-detail.vue')
  },
  // 资源广场
  {
    path: '/homeresour',
    component: () => import('@/views/resourceplaza/homeresour/home-resour.vue')
  },
  // 班级管理
  {
    path: '/classesadmin',
    component: () => import('@/views/classesadmin/homeclasses/home-classes.vue')
  }
]

const router = new VueRouter({
  routes,
  // 路由切换页面的滚动行为
  scrollBehavior (to, from, savedPosition) {
    // if (savedPosition) {
    //   // 如果之前有保存滚动位置，则恢复到之前保存的位置
    //   return savedPosition
    // } else {
    // 否则，默认滚动到页面顶部
    return { x: 0, y: 0 }
    // }
  }
})

// 修复 重复渲染路由 报错
// 获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
// 修改原型对象中的push方法
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
