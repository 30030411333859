import request from '@/utils/request'

// 获取讲义资源
export const GetHanout = (data) => request.get('/resources/handout/', { params: data })

// 发布讲义
export const SendHanout = (data) => request.put(`/resources/publish_handout/${data.id}/`, data)

// 复制讲义
export const Copyhanout = (data) => request.put(`/resources/copy_handout/${data.id}/`, data)

// 移动讲义
export const Movehanout = (data) => request.put(`/resources/move_handout/${data.id}/`, data)

// 删除讲义
export const DelHanout = (id) => request.delete(`/resources/handout/${id}/`)

// 制作讲义
export const MadeHanout = (data) => request.post('/resources/handout/', data)

// 获取讲义详情
export const GetHanoutDetail = (id) => request.get(`/resources/handout/${id}/`)

// 获取讲义素材 根据id列表
export const GetHanoutList = (data) => request.post('/resources/get_vegetable/', data)

// 获取讲义试题列表 根据id列表
export const GetHanoutTestList = (data) => request.post('/resources/get_exercises/', data)

// 修改讲义
export const PutHanout = (data) => request.put(`/resources/handout/${data.id}/`, data)

// 老师收藏讲义
export const TeacherCollectHanout = (id) => request.post(`/resources/collect_handout/${id}/`)

// 老师取消收藏讲义
export const TeacherCancelCollectHanout = (id) => request.delete(`/resources/collect_handout/${id}/`)
