<template>
  <div class="head-nav">
    <div class="content">
      <div class="left">
        <img @click="$router.push('/index')" src="../../assets/images/logo.png" alt="">
        <ul>
          <li @click="change_title(index)" :class="{active_nav:checked===index}" v-for="(item,index) in navlist" :key="index">{{item}}</li>
        </ul>
      </div>
      <div class="right">
        <div v-if="0" class="cell_hover">
          <el-badge   :value="1" class="item" is-dot>
            <i class="el-icon-bell"></i>
          </el-badge>
          <div class="sanjiao"></div>
          <div class="cell_item">
            <div class="cell_top">
              <p>消息通知</p>
              <p style="color: #999999;">全部标记为已读</p>
            </div>
            <el-empty description="暂无未读消息"></el-empty>
          </div>
        </div>
        <div class="user_hover">
          <div class="userinfo">
            <img :src="$store.getters.avatar" alt="">
            <p>{{$store.getters.name}}</p>
            <div class="down"><img src="../../assets/images/down.png" alt=""></div>
            <div class="sanjiao"></div>
            <div class="hover_item">
              <div class="caozuo">
                <ul>
                  <li @click="$router.push('/usercenter')">个人中心</li>
                  <li @click="downclient">下载客户端</li>
                  <li @click="LoginOut">退出</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Eum from '@/utils/eum'
export default {
  data () {
    return {
      navlist: Eum.navList,
      checked: ''
    }
  },
  created () {
    if (this.$route.path === '/index' || this.$route.path === '/madehand' || this.$route.path === '/lookres' || this.$route.path === '/pushstu') {
      this.checked = 0
    }
    if (this.$route.path === '/work-consoli' || this.$route.path === '/assignwork' || this.$route.path === '/lookreswork' || this.$route.path === '/pushstu1' || this.$route.path === '/perpush' || this.$route.query.name === '作业巩固') {
      this.checked = 1
    }
    if (this.$route.path === '/pushstuexam' || this.$route.path === '/lookresexam' || this.$route.path === '/testevalu' || this.$route.path === '/sendtest' || this.$route.path === '/testperpush' || this.$route.query.name === '考试测评') {
      this.checked = 2
    }
    // if (this.$route.path === '/intelpush' || this.$route.path === '/pushrecord') {
    //   this.checked = 3
    // }
    // if (this.$route.path === '/learnanaly') {
    //   this.checked = 4
    // }
    if (this.$route.path === '/homeresour' || this.$route.path === '/filedetail') {
      this.checked = 3
    }
    if (this.$route.path === '/classesadmin') {
      this.checked = 4
    }
  },
  methods: {
    change_title (index) {
      this.checked = index
      if (index === 0) {
        this.$router.push('/index')
      }
      if (index === 1) {
        this.$router.push('/work-consoli')
      }
      if (index === 2) {
        this.$router.push('/testevalu')
      }
      // if (index === 3) {
      //   this.$router.push('/intelpush')
      // }
      // if (index === 4) {
      //   this.$router.push('/learnanaly')
      // }
      if (index === 3) {
        this.$router.push('/homeresour')
      }
      if (index === 4) {
        this.$router.push('/classesadmin')
      }
    },
    LoginOut () {
      this.$confirm('此操作将退出该账号, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        customClass: 'oe-dialog-btn', // 为弹窗确认按钮加的类名，覆盖默认样式
        type: 'warning'
      }).then(() => {
        this.$store.dispatch('user/logout')
        // 清空章节单元
        this.$store.commit('constant/ClearChapItem', {})
        this.$message('退出登录成功 !', 'success')
        this.$router.push('/login')
      }).catch(() => {})
    },
    async  downclient () {
      const res = await this.$store.dispatch('user/downclient')
      // window.location.href = res[0].url
      window.open(res[0].url, '_blank')
    }
  }
}
</script>

<style lang="less" scoped>
.head-nav{
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background-color: var(--cl-primary);
  .content{
    padding: 0 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left{
      height: 80px;
      display: flex;
      align-items: center;
      img{
        cursor: pointer;
        width: 242px;
        height: 32px;
      }
      ul{
        display: flex;
        margin-left: 30px;
        li{
          margin-right: 45px;
          font-size: 18px;
          color: #FFFFFF;
          height: 75px;
          line-height: 80px;
          cursor: pointer;
        }
        .active_nav{
          font-weight: bold;
          border-bottom:3px solid #fff;
        }
      }
    }
    .right{
      cursor: pointer;
      display: flex;
      align-items: center;
      .el-icon-bell{
        font-size: 30px;
        color: #fff;
      }
      .item {
        font-size: 10px;
        margin-top: 10px;
        margin-right: 20px;
      }
      /deep/  .el-badge__content.is-fixed.is-dot {
        right:11px !important;
      }
      /deep/ .el-badge__content.is-fixed{
        top: 27px !important;
      }
      .user_hover{
        display: flex;
        align-items: center;
        height: 80px;
        .userinfo{
          position: relative;
          width: 191px;
          height: 40px;
          background: rgba(255,255,255,0.15);
          box-shadow: inset 2px 0px 10px 7px rgba(255,255,255,0.2);
          border-radius: 33px 33px 33px 33px;
          border: 2px solid rgba(255,255,255,0.4);
          display: flex;
          align-items: center;
          justify-content: space-between;
          img{
            width: 40px;
            height: 40px;
            border-radius: 33px 33px 33px 33px;
          }
          p{
            font-size: 16px;
            color: #FFFFFF;
          }
          .down{
            margin-bottom: 3px;
            display: flex;
            align-items: center;
            padding-right: 5px;
            img{
              width: 20px;
              height: 20px;
            }
          }
          .sanjiao{
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top:50px;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-bottom: 10px solid #fff;
            display: none;
          }
          .hover_item{
            display: none;
            position: absolute;
            left: 0;
            top: 60px;
            width: 190px;
            height: 220px;
            background: #FFFFFF;
            box-shadow: 0px 2px 6px 3px rgba(0,0,0,0.06);
            .caozuo{
              padding: 0 25px;
              ul{
                cursor: pointer;
                height: 220px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-evenly;
                li{
                  cursor: pointer;
                  border-radius: 3px;
                  width: 100%;
                  height: 40px;
                  line-height: 40px;
                  padding-left: 15px;
                  &:hover{
                    background-color: #f9f9f9;
                  }
                  &:last-child{
                    color: #FF5858;
                  }
                }
              }
            }
          }

        }
        &:hover{
          .sanjiao{
            display: block;
          }
          .hover_item{
            display: block;
          }
        }
      }
      .cell_hover{
        position: relative;
        height: 80px;
        line-height: 80px;
        .sanjiao{
            position: absolute;
            left: 8px;
            top:70px;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-bottom: 10px solid #fff;
            display: none;
        }
        .cell_item{
          display: none;
          position: absolute;
          right: -135px;
          top: 80px;
          width: 340px;
          height: 220px;
          background: #FFFFFF;
          box-shadow: 0px 2px 6px 3px rgba(0,0,0,0.06);
          border-radius: 0px 0px 0px 0px;
          .cell_top{
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 57px;
            border: 1px solid #F5F5F5;
            padding: 0 18px;
            p{
              font-size: 16px;
              color: #333333;
            }
          }
           /deep/  .el-empty{
              padding: 10px 0 !important;
            }
           /deep/   .el-empty__image{
              width: 85px !important;
            }
           /deep/.el-empty__description{
            margin-top: -15px;
           }
        }
        &:hover{
          .sanjiao{
            display: block;
          }
          .cell_item{
            display: block;
          }
        }
      }
    }
  }
}

</style>
