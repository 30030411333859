import request from '@/utils/request'

// 获取班级学生的数据
export const GetClassStudent = (data) => request.get('/users/students/', { params: data })

// 重置学生密码
export const ResetPassword = (id) => request.put(`/users/student/reset_password/${id}/`)

// 获取班级分组的数据
export const GetClassGroup = (data) => request.get('/users/student_group/', { params: data })

// 添加班级分组
export const AddClassFroup = (data) => request.post('/users/student_group/', data)

// 删除班级分组
export const DelGroup = (id) => request.delete(`/users/student_group/${id}/`)

// 编辑小组
export const EditGroup = (data) => request.put(`/users/student_group/${data.id}/`, data)

// 获取学生打分
export const GetStuScrce = (data) => request.get('/users/student/score/', { params: data })

// 学生加减法
export const ChangeStuScore = (data) => request.put(`/users/student/update_score/${data.id}/`, data)

// 学生批量加分
export const StuBatchScore = (data) => request.post('/users/batch_score/', data)

// 获取小组打分的信息
export const GetClassGroupScore = (data) => request.get('/users/student_group/', { params: data })

// 修改小组打分信息
export const ChangeGroupScore = (data) => request.put(`/users/update_group_score/${data.id}/`, data)
