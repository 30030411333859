import { AddSource, ContactSource, CancelContactSource, GetSource } from '@/api/source'

export default {
  namespaced: true,
  state: {

  },
  mutations: {

  },
  actions: {
    // 添加素材
    async AddSource ({ commit }, item) {
      const res = await AddSource(item)
      return res.data
    },
    // 收藏素材
    async ContactSource ({ commit }, id) {
      const res = await ContactSource(id)
      return res
    },
    // 取消收藏
    async CancelContactSource ({ commit }, id) {
      const res = await CancelContactSource(id)
      return res
    },
    // 获取素材详情
    async getsource ({ commit }, id) {
      const res = await GetSource(id)
      return res.data
    }
  }
}
