import request from '@/utils/request'
import qs from 'qs'
// 用户登录接口
export const reqLogin = (data) => request({
  method: 'post',
  url: '/users/teacher/login/',
  data: qs.stringify(data),
  headers: {
    Accept: 'application/json'
  }
})

// 获取手机验证码
export const GetPhoneCode = (data) => request.get('/sms_codes/', { params: data })

// 重置密码
export const PutPassword = (data) => request.post('/users/teacher/forgot_password/', data)

// 获取用户信息
export const GetUserInfo = () => request.get('/users/get_teacher_info/')

// 修改用户信息
export const PutUserInfo = (data) => request({
  method: 'put',
  url: `/users/teachers/${data.get('id')}/`,
  data,
  headers: { 'Content-Type': 'multipart/form-data' }
})

// 注销账号
export const CancelCoun = (id) => request.delete(`/users/teachers/${id}/`)

// 获取下载客户端链接
export const DownClient = () => request.get('/resources/client_package/')
