import { ChangeGroupScore, GetClassGroupScore, StuBatchScore, ChangeStuScore, GetStuScrce, EditGroup, GetClassStudent, ResetPassword, GetClassGroup, DelGroup, AddClassFroup } from '@/api/classadmin'

export default {
  namespaced: true,
  state: {

  },
  mutations: {

  },
  actions: {
    // 获取班级学生的数据
    async getclassstudent ({ commit }, item) {
      const res = await GetClassStudent(item)
      return res.data
    },
    // 重置学生的密码
    async resetpassword ({ commit }, id) {
      await ResetPassword(id)
    },
    // 获取班级小组和学生
    async getclassgroup ({ commit }, item) {
      const res = await GetClassGroup(item)
      return res.data
    },
    // 添加班级小组
    async addclassgroup ({ commit }, item) {
      await AddClassFroup(item)
    },
    // 删除班级小组
    async delgroup ({ commit }, item) {
      await DelGroup(item)
    },
    // 编辑班级小组
    async editgroup ({ commit }, item) {
      await EditGroup(item)
      // console.log(res)
    },
    // 获取学生打分
    async getstuscore ({ commit }, item) {
      const res = await GetStuScrce(item)
      return res.data
    },
    // 学生加减分
    async changesco ({ commit }, item) {
      await ChangeStuScore(item)
    },
    // 学生批量加分
    async stubatchsco ({ commit }, item) {
      await StuBatchScore(item)
    },
    // 获取小组打分
    async getgroupscore ({ commit }, item) {
      const res = await GetClassGroupScore(item)
      return res.data.results
    },
    // 修改小组打分
    async changegroupscore ({ commit }, item) {
      await ChangeGroupScore(item)
      // console.log(res)
    }
  }
}
