import request from '@/utils/request'

// 获取讲义库的资源
export const GetHanout = (data) => request.get('/resources/handout/', { params: data })

// 获取作业库的资源
export const GetWork = (data) => request.get('/resources/homework/', { params: data })

// 获取考试库的资源
export const GetKaoShi = (data) => request.get('/resources/exam/', { params: data })

// 获取习题库的资源
export const GetXiti = (data) => request.get('/resources/exercises/', { params: data })

// 获取素材库的资源
export const GetSucai = (data) => request.get('/resources/vegetable/', { params: data })

// 发请求获取所有老师
export const GetAllTeacher = (data) => request.get('/users/teachers/', { params: data })
