import request from '@/utils/request'
// 获取常量类接口

// 获取科目的数据
export const GetSubject = () => request.get('/users/subjects/')

// 获取教材版本
export const GetVersion = (id) => request.get(`/resources/bookversion/?subject=${id}`)

// 获取章节单元
export const GetChapters = (data) => request.get('/resources/section/', { params: data })

// 获取二级章节单元知识点
export const GetTwoChapter = (data) => request.get('/resources/section_knowledge_points/', { params: data })

// 获取班级小组和学生
export const GetGroupStu = (data) => request.get('/users/get_classes_info/', { params: data })
