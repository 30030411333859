<template>
  <div ref="chartzhe" class="chart_zhe"></div>
</template>

<script>

export default {
  mounted () {
    // const myChart = this.$echarts.init(this.$refs.chartzhe)
    // const option = {
    //   legend: {
    //     type: 'plain',
    //     show: true
    //   },
    //   tooltip: {
    //     trigger: 'axis'
    //   },
    //   dataZoom: [
    //     {
    //       type: 'slider',
    //       show: true,
    //       start: 0,
    //       end: 100, // 控制显示的数据范围
    //       height: '25'
    //     }
    //   ],
    //   xAxis: {
    //     type: 'category',
    //     data: ['4月1号', '4月2号']
    //   },
    //   yAxis: {
    //     type: 'value'
    //   },
    //   series: [
    //     {
    //       data: [150, 230],
    //       type: 'line',
    //       name: '602班'
    //     }
    //   ]
    // }
    // myChart.setOption(option)
  }
}
</script>

<style lang="less" scoped>
    .chart_zhe{
      width: 900px;
      height: 380px;
      margin: 20px auto;
    }
</style>
