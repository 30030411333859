import request from '@/utils/request'

// 上传试题
export const UploadTestQUestion = (data) => request.post('/resources/exercises/', data)

// 获取试题详情
export const GetTestQuestionDetail = (id) => request.get(`/resources/exercises/${id}/`)

// 修改试题
export const PutTsetQuestion = (data) => request.put(`/resources/exercises/${data.id}/`, data)

// 收藏试题
export const ContactTest = (id) => request.post(`/resources/collect_exercises/${id}/`)

// 取消收藏试题
export const CancelContactTest = (id) => request.delete(`/resources/collect_exercises/${id}/`)
