import { GetWorkPushDetail, GetWorkPushRes, PushTest, GetWorkPush, LookKnowRes, LookrateRes, LookStuDetail, LookStuRes, PutWork, AddWork, DelWork, SendWork, MoveJob, TeacherClooectJob, CancelTeacherClooectJob, GetJobDetail, GetJob } from '@/api/job'

export default {
  namespaced: true,
  state: {

  },
  mutations: {

  },
  actions: {
    // 老师收藏作业
    async teacollectjob ({ commit }, id) {
      await TeacherClooectJob(id)
    },
    // 老师取消收藏作业
    async cancelteacollectjob  ({ commit }, id) {
      await CancelTeacherClooectJob(id)
    },
    // 获取作业详情
    async getjobdetail ({ commit }, id) {
      const res = await GetJobDetail(id)
      return res.data
    },
    // 获取作业信息
    async getjob ({ commit }, item) {
      const res = await GetJob(item)
      return res.data
    },
    // 移动作业
    async movejob ({ commit }, item) {
      await MoveJob(item)
    },
    // 发布作业
    async senwork ({ commit }, id) {
      await SendWork(id)
    },
    // 删除作业
    async delwork ({ commit }, id) {
      await DelWork(id)
    },
    // 添加作业
    async addwork ({ commit }, item) {
      await AddWork(item)
      // console.log(res)
    },
    // 修改作业
    async putwork ({ commit }, item) {
      await PutWork(item)
    },
    // 按学生查看作业结果
    async lookstures ({ commit }, item) {
      const res = await LookStuRes(item)
      return res.data
    },
    // 按学生查看 查看详情
    async lookstudetail ({ commit }, id) {
      const res = await LookStuDetail(id)
      return res.data
    },
    // 按试题正确率查看
    async lookrateres ({ commit }, item) {
      const res = await LookrateRes(item)
      return res.data
    },
    // 按知识点掌握度查看
    async lookknowres ({ commit }, item) {
      const res = await LookKnowRes(item)
      return res.data
    },
    // 获取作业推送记录
    async getworkpush ({ commit }, id) {
      const res = await GetWorkPush(id)
      return res.data
    },
    // 发送个性化习题
    async pushtest ({ commit }, item) {
      const res = await PushTest(item)
      return res.data
    },
    // 获取作业推送结果
    async getworkrespush ({ commit }, id) {
      const res = await GetWorkPushRes(id)
      return res.data
    },
    // 获取作业推送结果详情
    async getworkpushdetail ({ commit }, id) {
      const res = await GetWorkPushDetail(id)
      return res.data
    },
    // 清除布置作业时 本地存储的 作业内容 和数据
    clearnJobItem () {
      localStorage.removeItem('ResponFileListJob') // 清除作业表格内容
      localStorage.removeItem('classJob') // 清除填写的作业信息
      localStorage.removeItem('AcceptObj') // 清除 全部老师 学生 数据
      localStorage.removeItem('NewAcceptObj')// 清除 id回显 发请求 全部老师 学生 数据
      localStorage.removeItem('editsectionjob')
      localStorage.removeItem('jobId')
      localStorage.removeItem('sectionJob') // 点击查看结果进来的 二级章节id
    }
  }
}
